<template>
  <div>
    
  </div>
</template>

<script>
export default {
    props:{
        order:{
            type:Object,
            required: true
        }
    },
    data(){
        return{
        }
    },
    computed:{
       
    },
    methods:{

    }
}
</script>

<style>

</style>