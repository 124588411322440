<template>
  <div>
     <vue-dropzone id="messageUploadDrop" ref="messageUploadDrop"
      :use-custom-slot="true">
      <div class="needsclick m-0">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload-cloud"><polyline points="16 16 12 12 8 16"></polyline><line x1="12" y1="12" x2="12" y2="21"></line><path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"></path><polyline points="16 16 12 12 8 16"></polyline></svg>
        <h4>Drop files here or click to upload.</h4>
        <span class="text-primary font-13">
            <em>Maximum of 10 files</em>
        </span>
      </div>
    </vue-dropzone>
    <div class="my-3">
        <vs-input class="flex-1 w-full" placeholder="Send with message" v-model="data.content" />
    </div>
    <div class="flex flex-wrap justify-end items-center mt-5">
        <vs-button type="filled" @click.prevent="sendAttachment()" class="">Upload</vs-button>
    </div>
</div>
</template>

<script>
import VueDropzone from "../VueDropzone.vue";
export default {
    components:{
        VueDropzone,
    },
    props:{
        orderId:{
            type: Number,
            required:true
        }
    },
    data() {
        return {
            data:{
                title:"Message attachment",
                content:"Find below attached files",
                icon: "ImageIcon",
                order_id: this.orderId,
                type: "ORDER_MESSAGE",
                url: `/orders/${this.orderId}`
            },
        }
    },
    methods: {
        sendAttachment() {
            if(!this.collectFiles().length){ this.alertError("Please a file is required"); return;}
            let formData = new FormData();
            formData.append('data', JSON.stringify(this.data))
            this.collectFiles().forEach((item, index) => formData.append('file_'+index, item))
            this.$vs.loading({ color: "#444", type: "sound"})
            this.$http.post("/activities/attachments", formData)
            .then((response) => {
                this.$vs.loading.close()
                if(response.data.success){
                    this.$store.commit("orderList/ADD_ACTIVITY", response.data.data)
                    this.$emit("closeMe");
                }
            })
        },
        collectFiles(){
            let arrafile = [];
            if(this.$refs.messageUploadDrop){
                arrafile = [...this.$refs.messageUploadDrop.getAcceptedFiles()];
            }
            return arrafile
        },
    }
}

</script>

