
<template>
  <div class="order-attachments">
    <div class="vx-row justify-left">
      <div v-for="(file, fIndex) of files" :key="fIndex" 
        :class="`vx-col mb-5 w-full ${columnClass}`">
        <div class="shadow">
          <div class="file-box" >
            <div @click="$emit('showFile', fIndex)" v-if="['jpeg','jpg','svg','png','webp'].includes(file.extension)" class="img h-full w-full"
              :style="`background-image: url(${absoluteUrl(file.path)})`">
            </div>
            <div v-else class="others">
              {{file.extension}}
            </div>
          </div>
          <div class="p-2 flex justify-between">
            <span class="hidden text-sm sm:inline">{{file.name | truncate(4)}} </span>
            <strong class="mr-1 text-sm">{{file.extension}}</strong> 
            <span class="text-dark text-sm mr-1"> ({{file.size}})</span>
            <a :href="`${absoluteUrl()}/download?file_path=${file.path}`" :download="file.name" class="text-success downloadable flex text-sm">
              <feather-icon icon="DownloadIcon" :svgClasses="'icon-config'" class="h-6"></feather-icon>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
    name: "order-attachments",
    props: {
        files: {
            type: Array,
            default: () => []
        },
        columnClass:{
            type: String,
            default: "sm:w-1/2 md:w-1/3",
        }
    },

}
</script>

<style >
.order-attachments .downloadable{
  font-size: 12px;
}
.order-attachments .icon-config{
  width:12px !important;
  height: 12px !important;
}

.order-attachments .file-box{
  width: 100%;
  height: 100px;
}

@media(max-width:568px){
    .order-attachments .file-box{
      height:80px;
    }
}

.order-attachments .file-box .img{
    height:100%;
    background-position: center center;
    background-size: cover;
}

.order-attachments .file-box .others{
  width:100%;
  height:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  background: #eee;
}
.order-attachments .file-box .others:hover{
  background: #ccc;
}
</style>
