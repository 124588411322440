<template>
    <div style="height:80px;" class="vs-con-loading__container loading-example">
        <div style="background:transparent !important;" class="con-vs-loading vs-loading-background-undefined vs-loading-color-undefined">
            <div style="width: 40px; height: 40px;" class="vs-loading default">
                <div class="effect-1 effects"></div>
                <div class="effect-2 effects"></div>
                <div class="effect-3 effects"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>