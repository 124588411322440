<template>
  <div class="order-show" id="order-view">
    <div v-if="!isLoading">
      <div class="vx-row">
        <div class="vx-col md:w-2/3 w-full">
          <vs-tabs :value="activeTab" position="top" class="tabs-shadow-none" id="profile-tabs" :key="isSmallerScreen">
              <vs-tab :label="!isSmallerScreen ? 'ACTIVITY' : 'ACT'">
                <div class="tab-activity mt-5 ml-0">
                    <order-activity class="mb-3" />
                </div>
              </vs-tab>
              <vs-tab :label="!isSmallerScreen ? 'DETAILS' : 'DET'">
                <div class="tab-details mt-5 ml-0">
                    <order-overview class="mb-3" />
                </div>
              </vs-tab>
              <vs-tab :label="!isSmallerScreen ? 'REQUIREMENTS' : 'REQ'">
                <div class="tab-requirements mt-5 ml-0">
                  <order-requirements  class="mb-3" />
                </div>
              </vs-tab>
          </vs-tabs>
        </div>
        <div class="vx-col pt-12 md:w-1/3 hidden md:block w-full">
          <order-sidebar-details />
        </div>
      </div>
    </div>
    <is-loading v-if="isLoading" />
  </div>
</template>

<script>
import OrderActivity from "@/components/order/OrderActivity.vue"
import OrderOverview from "@/components/order/OrderOverview.vue"
import OrderRequirements from "@/components/order/OrderRequirements.vue"
import OrderSidebarDetails from "@/components/order/OrderSidebarDetails.vue"
import IsLoading from '@/components/IsLoading.vue'

export default {
  components: {
    OrderActivity,
    OrderOverview,
    OrderRequirements,
    OrderSidebarDetails,
    IsLoading
  },
  data() {
    return {
      isLoading: true,
    }
  },
  watch: {
    "$route":"fetchOrder"
  },
  computed: {
    isSmallerScreen() {
      return this.$store.state.windowWidth < 768
    },
    order(){
      return this.$store.state.orderList.order
    },
    activeTab(){
      return this.$route.query.tab
        ? +this.$route.query.tab
        : 0;
    },
  },
  methods:{
    fetchOrder(){
      this.isLoading = true
      this.$store.dispatch("orderList/fetchOrder",this.$route.params.orderId)
      .then((response) => {
        this.isLoading = false
        if(!response.data.success){
          this.$router.push({path: "/error-404"})
        }
      })
    },
  },
  created(){
    this.fetchOrder();
  },
}
</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
.con-vs-tabs{
    .con-ul-tabs{
        .vs-icon{
            font-size:1.5rem;
        }
    }
}
.order-show .vs-tabs--li button.vs-tabs--btn{
  padding: 10px 17px;
}
</style>
