<template>
    <div class="row delivery-unit">
        <div class="mb-4">
            <div class="">
                 <vue-dropzone id="dropzoneUnit" ref="deliveryZone"
                    :use-custom-slot="true">
                    <div class="needsclick m-0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload-cloud"><polyline points="16 16 12 12 8 16"></polyline><line x1="12" y1="12" x2="12" y2="21"></line><path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"></path><polyline points="16 16 12 12 8 16"></polyline></svg>
                        <h4>Drop files here or click to upload.</h4>
                        <span class="text-primary font-13">
                            <em>(Ai, video files should be zipped)</em>
                        </span>
                    </div>
                </vue-dropzone>
            </div>
        </div>
        <div class="">
            <div>
            <vs-textarea class="w-full" rows="2" name="content" v-validate="{required: true, max:1000}" 
                counter="1000" label="Type message here" v-model="data.content" />
                <span class="text-danger text-sm"  v-show="errors.has('content')">{{ errors.first('content') }}
                </span>
            </div>
            <div class="">
                <div class="flex flex-wrap justify-end items-center mt-5">
                    <vs-button type="filled" @click.prevent="create()" class="">Send Delivery</vs-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import VueDropzone from "@/components/VueDropzone.vue";

export default {
    components: {
        VueDropzone,
    },
    props:{
        orderId:{
            type: Number,
            required:true
        }
    },
    data(){
        return {
            data:{
                title:"Delivered file(s)",
                order_id: this.orderId,
                content: "",
                url: `/orders/${this.orderId}`,
                type: "ORDER_DELIVERY"
            },
        }
    },
    methods:{
        create(){
            this.$validator.validateAll().then(result => {
                if (result) {
                    if(!this.collectFiles().length){this.alertError("at least a file is Required"); return;}
                    let formData = new FormData();
                    formData.append('data', JSON.stringify(this.data))
                    this.collectFiles().forEach((item, index) => formData.append('file_'+index, item))
                    this.$vs.loading({ color: "#444", type: "sound"})
                    this.$http.post('/activities/attachments', formData)
                        .then(response => {
                            this.$vs.loading.close()
                            if(response.data.success){
                                this.initializeAll()
                                // this.$store.commit("orderList/ADD_ACTIVITY", response.data.data.activity)
                                this.$store.dispatch("orderList/fetchOrder", this.orderId)
                            }
                        })
                }
            })
        },
        collectFiles(){
            let arrafile = [];
            if(this.$refs.deliveryZone){
                arrafile = [...this.$refs.deliveryZone.getAcceptedFiles()];
            }
            return arrafile
        },
        initializeAll(){
            this.data.content = ""
            if(this.$refs.deliveryZone){this.$refs.deliveryZone.removeAllFiles();}
            this.$validator.reset()
        },

    }
}
</script>
